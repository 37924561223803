import React, { useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import LinearProgress from "@mui/material/LinearProgress";
import Grid from "@mui/material/Grid";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { SimpleSnackbar } from "./components/SimpleSnackBar";

const HCAPTCHA_SITE_KEY =
  process.env.NODE_ENV === "production"
    ? process.env.RAZZLE_HCAPTCHA_SITE_KEY
    : "10000000-ffff-ffff-ffff-000000000001";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#ffa90a",
    },
  },
});

const Home: React.FC = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleSubmission = (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("title", event.target.title.value);
    formData.append("models", event.target.model.value);
    formData.append("tags", event.target.tags.value);
    formData.append("captchaToken", captchaToken);
    const xhr = new XMLHttpRequest();

    xhr.upload.addEventListener("progress", (evt) => {
      setProgress(Math.round((evt.loaded / evt.total) * 100));
    });

    xhr.upload.addEventListener("load", () => {
      setUploading(false);
      setOpenSnackBar(true);
      event.target.reset();
    });

    xhr.open("POST", `/profile`);
    xhr.send(formData);
    setUploading(true);
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h1" variant="h5">
            Subir Vídeo
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmission}
            action="/profile"
            method="post"
            encType="multipart/form-data"
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              label="Título"
              name="title"
              autoFocus
              inputProps={{
                maxLength: "100",
              }}
            />
            <TextField
              margin="normal"
              fullWidth
              label="Modelos (opcional)"
              helperText={
                <>
                  Todos en minúsculas.
                  <br />
                  Separados por coma.
                  <br />
                  Sin caracteres especiales.
                  <br />
                  Sin espacios.
                  <br />
                  Sin coma al final.
                </>
              }
              name="model"
              inputProps={{
                maxLength: "100",
                pattern: "([a-z0-9])+(,[a-z0-9]+)*",
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              label="Tags"
              helperText={
                <>
                  Mínimo 1.
                  <br />
                  Todos en minúsculas.
                  <br />
                  Separados por coma.
                  <br />
                  Sin caracteres especiales.
                  <br />
                  Sin espacios.
                  <br />
                  Sin coma al final.
                </>
              }
              name="tags"
              inputProps={{
                maxLength: "100",
                pattern: "([a-z0-9])+(,[a-z0-9]+)*",
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="file"
              type="file"
              onChange={changeHandler}
              inputProps={{
                accept: "video/mp4",
              }}
            />
            {uploading && (
              <LinearProgress variant="determinate" value={progress} />
            )}
            <Grid container justifyContent="center">
              <HCaptcha
                sitekey={HCAPTCHA_SITE_KEY}
                onVerify={setCaptchaToken}
              />
            </Grid>
            <Button
              type="submit"
              disabled={
                uploading || selectedFile === null || captchaToken === null
              }
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
              }}
            >
              Subir
            </Button>
          </Box>
        </Box>
      </Container>
      <SimpleSnackbar open={openSnackBar} setOpen={setOpenSnackBar} />
    </ThemeProvider>
  );
};

export default Home;
